// extracted by mini-css-extract-plugin
export const indexFullpage = "index-module--index--fullpage--qB0SX";
export const item5 = "index-module--item-5--1cWEu";
export const indexPage = "index-module--index--page--1O42H";
export const item1 = "index-module--item-1--2de2C";
export const item2 = "index-module--item-2--2tE-u";
export const item3 = "index-module--item-3--2OIdT";
export const item4 = "index-module--item-4--xfgjp";
export const item6 = "index-module--item-6--3ngwI";
export const item7 = "index-module--item-7--2b_Bb";
export const item8 = "index-module--item-8--3a7lg";
export const item9 = "index-module--item-9--22xo1";
export const indexImg = "index-module--index--img--3BADO";
export const indexImg__box = "index-module--index--img__box--f0Hf_";